import React from "react";
import {Switch, Route, Redirect} from "react-router";

/**
 * Import all page components here
 */
import App from "./containers/App/App";
import Firebase, {FirebaseContext} from "./components/Firebase";

/**
 * All routes go here.
 * Don't forget to import the components above after adding new route.
 *
 */

const EmptyPage = () => {
    window.addEventListener("message", (message) => {
        if (message.origin !== "https://push.witheyezon.com") {
            return;
        }
        if (message.data === "checkNotifications") {
            message.source.postMessage(
                JSON.stringify({
                    permission: Notification.permission,
                    type: "notificationResponse",
                }),
                "*"
            );
        }
    });
    return null;
};

const closeWindow = () => {
    window.close();
};

export function Routes() {
    return (
        <>
            <Switch>
                <Redirect from="/" exact to="/client/null/ru"/>
                <Route
                    exact
                    path="/client/:clientId/:language"
                    render={(props) => (
                        <FirebaseContext.Provider value={Firebase ? new Firebase() : null}>
                            <App closeWindow={closeWindow} {...props} />
                        </FirebaseContext.Provider>
                    )}
                />
                <Route
                    exact
                    path="/client/:clientId/:language/:region"
                    render={(props) => (
                        <FirebaseContext.Provider value={Firebase ? new Firebase() : null}>
                            <App closeWindow={closeWindow} {...props} />
                        </FirebaseContext.Provider>
                    )}
                />
                <Route
                    exact
                    path="/client/:clientId"
                    render={(props) => (
                        <FirebaseContext.Provider value={Firebase ? new Firebase() : null}>
                            <App closeWindow={closeWindow} {...props} />
                        </FirebaseContext.Provider>
                    )}
                />
                <Route exact path="/checknotifications" component={EmptyPage}/>
            </Switch>
        </>
    );
}

export default Routes;
