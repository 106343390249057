import * as firebase from "firebase/app";
import "firebase/messaging";
import {
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_APP_ID,
  REACT_APP_PUBLIC_VAPID_KEY,
} from "../../prod_const";

const config = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DATABASE_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: "G-8B10VNSX7X",
};

let messaging = null;

try {
  const initializedFirebaseApp = firebase.initializeApp(config);
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
      // Project Settings => Cloud Messaging => Web Push certificates
      REACT_APP_PUBLIC_VAPID_KEY
  );
} catch (e) {

}

class Firebase {
  constructor() {
    this.messaging = messaging;
    this.getMessaging = () => {
      return this.messaging;
    };
  }
}
export default Firebase;
