import React, {useEffect, useMemo, useState} from 'react';
import './bg.css';

export function Bg({pushBg, widgetColor}) {
    const [bgSvg, setBgSvg] = useState(null);
    useEffect(() => {
        if (pushBg && widgetColor) {
            (async () => {
                try {
                    const newBgSvg = await fetch(pushBg).then(data => data.text()).then(text => text.replace(/#FF2D55/g, widgetColor.replace(/[^#a-zA-Z0-9]/g, '')));
                    setBgSvg(newBgSvg);
                } catch (e) {
                }
            })();
        }
    }, [pushBg, widgetColor])

    return useMemo(() => {
            if (!bgSvg) {
                return null;
            }
            return <div id="bg" dangerouslySetInnerHTML={{
                __html: bgSvg
            }}>
            </div>
        },
        [bgSvg]);
}