import React, {Component} from "react";
import styled from "styled-components";

import {media} from "../../utils/media/media";
import {withFirebase} from "../../components/Firebase";
import axios from "axios";
import {SERVERS} from "../../consts";
import {Bg} from "./Bg/Bg";

const io = require("socket.io-client");

const Main = styled.div`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  background-position: top right;
  background-repeat: no-repeat;
  height: 100vh;
`;

const Container = styled.div`
  position: relative;
  z-index: 2;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const LogoWrap = styled.div`
  width: 110px;
  height: 37px;
  margin-top: 24px;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 0 30px;
  width: 100%;
`;

const InfoBlockHeader = styled.div`
  font-weight: 800;
  font-size: 44px;
  line-height: 135%;
  width: 550px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.02em;
  color: #24262c;
  ${media.tablet`
    font-size: 24px;
    line-height: 140%;
    letter-spacing: 0.02em;
    width: 280px ;
  `};
`;

const InfoBlockText = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 170%;
  max-width: 330px;
  color: #24262c;
  margin: 12px 0 32px;
  ${media.tablet`
    margin: 15px 0 32px;
    font-size: 14px;
    line-height: 170%;
  `};
`;

const NotifyButton = styled.button`
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background: ${props => props.bg};
  color: #ffffff;
  width: 210px;
  padding: 15px 19px;
  box-sizing: border-box;
  box-shadow: none;
  -webkit-appearance: none;
  opacity: 1;
  margin: 0;

  &:hover, :focus {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.95;
  }

  &:focus {
    outline: none;
  }
`;

const LinksBlock = styled.div`
  width: 300px;
  margin-bottom: 56px;
`;

const Descr = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 170%;
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.4;
  margin-bottom: 4px;
`;

const Links = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

const Link = styled.li`
  font-size: 14px;
  line-height: 170%;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #ff2d55;
`;

const links = ["Google chrome", "FireFox", "Opera"];
const IF_INSTRUCTION = false;

const urlParams = new URLSearchParams(window.location.search);
const customWidgetColor = urlParams.get('color');
const customLogo = urlParams.get('logo');
const customFavicon = urlParams.get('favicon');

const STATUS = {
    INITIAL: "INITIAL",
    GRANTED: "GRANTED",
    DENIED: "DENIED"
};

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: props.match ? props.match.params.clientId || "null" : "null",
            language: props.match ? props.match.params.language || "ru" : "ru",
            region: props.match ? props.match.params.region || "eu-1" : "eu-1",
            status: STATUS.INITIAL,
            externalPushHeader: "",
            externalPushPermissionText: "",
            externalPushDeniedText: "",
            externalPleaseText: "",
            externalPushSuccessText: "",
            externalPushCanCloseText: "",
            externalPushButton: "",
            externalPushLogo: "",
            pushBg: "",
            widgetColor: customWidgetColor ?? '#FF2D55'
        };
    }

    componentDidMount() {
        let link = document.createElement('link');
        link.rel = 'icon';
        link.href = customFavicon ?? '/_favicon.png';
        document.head.appendChild(link);

        let self = this;
        axios
            .get(
                `https://storage.googleapis.com/eyezon_language/button/${self.state.language}.json`
            )
            .then((response) => {
                const {
                    pushNotificationText,
                    externalPushHeader,
                    externalPushPermissionText,
                    externalPushDeniedText,
                    externalPleaseText,
                    externalPushSuccessText,
                    externalPushCanCloseText,
                    externalPushButton,
                    externalPushLogo,
                    pushBg
                } = response.data;
                self.setState({
                    pushNotificationText,
                    externalPushHeader,
                    externalPushPermissionText,
                    externalPushDeniedText,
                    externalPushSuccessText,
                    externalPushCanCloseText,
                    externalPleaseText,
                    externalPushButton,
                    externalPushLogo: customLogo ?? externalPushLogo,
                    pushBg
                });
                document.title = pushNotificationText;
            })
            .catch((error) => {
                axios
                    .get(`https://storage.googleapis.com/eyezon_language/button/ru.json`)
                    .then((response) => {
                        const {
                            pushNotificationText,
                            externalPushHeader,
                            externalPushPermissionText,
                            externalPushDeniedText,
                            externalPleaseText,
                            externalPushSuccessText,
                            externalPushCanCloseText,
                            externalPushButton,
                            externalPushLogo,
                            pushBg
                        } = response.data;
                        self.setState({
                            pushNotificationText,
                            externalPushHeader,
                            externalPushPermissionText,
                            externalPushDeniedText,
                            externalPushSuccessText,
                            externalPushCanCloseText,
                            externalPleaseText,
                            externalPushButton,
                            externalPushLogo: customLogo ?? externalPushLogo,
                            pushBg
                        });
                    });
            });

        const {userId} = self.state;
        this.socket = io(SERVERS[this.state.region].SOCKET_URL, {
            transports: ["websocket"],
            upgrade: false,
        });
        self.socket.emit("enterSocket", userId);
        this.socket.on("pushStatusChange", (response) => {
            if (response.status) {
                self.props.closeWindow && self.props.closeWindow();
            }
        });
        if ("Notification" in window) {
            if (Notification.permission === "granted") {
                this.allowNotifications();
            }
        }
    }

    allowNotifications = () => {
        let self = this;
        const {userId} = self.state;

        const serviceUrl = "/firebase-messaging-sw.js";
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register(serviceUrl, {
                    scope: '/'
                })
                .then((registration) => {
                    try {
                        let messaging = self.props.firebase.getMessaging();
                        messaging
                            .requestPermission()
                            .then(async () => {
                                const fcmToken = await messaging.getToken();

                                const url = SERVERS[this.state.region].API_BASE_URL + `/client/${userId}/fcm`;
                                axios
                                    .put(url, {token: fcmToken})
                                    .then((response) => {
                                        self.socket.emit(
                                            "setPushStatus",
                                            JSON.stringify({userId: userId, status: true})
                                        );
                                        const data = JSON.stringify({
                                            clientId: userId,
                                            notifications: {
                                                push: true,
                                            },
                                        });
                                        self.socket.emit("changeClientNotifications", data);
                                        self.setState({
                                            status: STATUS.GRANTED
                                        });
                                    })
                                    .catch((error) => {
                                        self.setState({
                                            status: STATUS.DENIED
                                        });
                                        self.socket.emit(
                                            "setPushStatus",
                                            JSON.stringify({userId: userId, status: false})
                                        );
                                    });

                            })
                            .catch(function (error) {
                                self.setState({
                                    status: STATUS.DENIED
                                });
                                self.socket.emit(
                                    "setPushStatus",
                                    JSON.stringify({userId: userId, status: false})
                                );
                            });

                        messaging.onMessage((payload) => {
                            if (navigator.serviceWorker.controller) {
                                navigator.serviceWorker.controller.postMessage(payload)
                            }
                        });
                    } catch (e) {
                        self.setState({
                            status: STATUS.DENIED
                        });
                    }

                })
                .catch(function (err) {
                    self.setState({
                        status: STATUS.DENIED
                    });
                });
        } else {
            self.setState({
                status: STATUS.DENIED
            });
        }
    };

    render() {
        let heading;
        let text;
        switch (this.state.status) {
            case STATUS.GRANTED:
                heading = this.state.externalPushSuccessText;
                text = this.state.externalPushCanCloseText;
                break;
            case STATUS.DENIED:
                heading = this.state.externalPushDeniedText;
                text = this.state.externalPleaseText;
                break;
            default:
                heading = this.state.externalPushHeader;
                text = this.state.externalPushPermissionText;
                break;
        }

        return (
            <>
                <Bg pushBg={this.state.pushBg} widgetColor={this.state.widgetColor}/>
                <Main>
                    <Container>
                        <LogoWrap>
                            {this.state.externalPushLogo &&
                            <img src={this.state.externalPushLogo} alt=""/>
                            }
                        </LogoWrap>
                        <InfoBlock>
                            <InfoBlockHeader>{heading}</InfoBlockHeader>
                            <InfoBlockText>{text}</InfoBlockText>
                            {(this.state.status === STATUS.INITIAL || this.state.status === STATUS.DENIED) && <>
                                <NotifyButton
                                    onClick={this.allowNotifications}
                                    bg={this.state.widgetColor}
                                >
                                    {this.state.externalPushButton}
                                </NotifyButton>
                            </>}
                        </InfoBlock>

                        <LinksBlock>
                            {IF_INSTRUCTION && (
                                <>
                                    <Descr>Как включить уведомления:</Descr>
                                    <Links>
                                        {links.map((it, idx) => (
                                            <Link key={`link-${idx}`}>{it}</Link>
                                        ))}
                                    </Links>{" "}
                                </>
                            )}
                        </LinksBlock>
                    </Container>
                </Main>
            </>
        );
    }
}

export default withFirebase(App);
