const API_VERSION = "";

export const SERVERS = {
    "eu-1": {
        SOCKET_URL: "https://servereu.eyezon.online" + API_VERSION,
        API_BASE_URL: "https://servereu.eyezon.online/api" + API_VERSION,
        STREAMING_URL: "wss://wsseu.eyezon.online",
    },
    "ru-1": {
        SOCKET_URL: "https://serverru.eyezon.online" + API_VERSION,
        API_BASE_URL: "https://serverru.eyezon.online/api" + API_VERSION,
        STREAMING_URL: "wss://wssru.eyezon.online",
    },
    "us-1": {
        SOCKET_URL: "https://serverus.eyezon.online" + API_VERSION,
        API_BASE_URL: "https://serverus.eyezon.online/api" + API_VERSION,
        STREAMING_URL: "wss://wssus.eyezon.online",
    },
    sandbox: {
        SOCKET_URL: "https://serversandbox.eyezon.online" + API_VERSION,
        API_BASE_URL: "https://serversandbox.eyezon.online/api" + API_VERSION,
        STREAMING_URL: "wss://wsssandbox.eyezon.online",
    },
};
